<template>
  <button
    :class="['launch-button', customClass, { 'is-loading': loading }]"
    @click="$emit('click')"
    :disabled="disabled || loading"
  >
    <div class="button-content">
      <span v-if="loading" class="spinner"></span>
      <span class="button-text">
        <slot>{{ label }}</slot>
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'LaunchButton',
  props: {
    label: {
      type: String,
      default: 'Lançar notas'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.launch-button {
  background-color: #4da4e9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0;
  text-transform: uppercase;
  font-size: 14px;
  min-width: 150px;
}

.button-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
}

.launch-button:hover {
  background-color: #2e92d1;
}

.launch-button:active {
  transform: translateY(1px);
}

.launch-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(77, 164, 233, 0.3);
}

.launch-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.is-loading {
  background-color: #4da4e9;
  cursor: wait;
}

.is-loading .button-text {
  opacity: 0.5;
}

.spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .launch-button {
    width: 100%;
    padding: 12px;
    font-size: 13px;
  }
}
</style>